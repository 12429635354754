<template>
  <div class="scheme-contain">
  <div class="data-contain">
    <div class="card-wrapper">
      <div class="tab-contain">
        <router-link class="static-button" :to="{name: 'Train'}">统计显示</router-link>
        <router-link :to="{name: 'TrainTable'}">数据显示</router-link>
        <router-link class="chart-button" :to="{name: 'TrainReport'}">训练报表</router-link>
      </div>
    </div>

    <div class="table-box">
      <Table :columns="columns" :data="trainList" ref="table" @on-selection-change="handleSelect"></Table>

      <div>
        <span class="records">共{{pageTotal}}条记录</span>
      </div>
    </div>

    <div class="page-nations-wrapper">
      <div class="page-nations">
        <Page :total="pageTotal" :current="cur_page" :page-size="trainData.size" @on-change="changePage"></Page>
      </div>
    </div>

  </div>
  </div>
</template>
<script type="text/ecmascript-6">
import MemberService from '@/services/memberService';
export default {
	data() {
		return {
			time: [],
			columns: [
				{ title: '报告名称', key: 'bgmc', align: 'center' },
				{ title: '报告日期', key: 'bgrq', align: 'center' },
				{ title: '检测师', key: 'jcs', align: 'center' },
				{ title: '咨询师', key: 'zxs', align: 'center' },
				{ title: '解读状态', key: 'jdzt', align: 'center' },
				{
					title: '操作',
					key: 'action',
					width: 150,
					align: 'center',
					render: (h, params) => {
						if (this.power.indexOf('会员管理--删除会员信息权限') > -1) {
							return h('div', [
								h('a', {
									class: 'icon_eye',
									on: {
										click: () => {
											let id = params.row.id;
											this.$router.push({
												name: 'TrainReportDetail',
												params: { member_id: this.memberId, train_id: id },
											});
										},
									},
								}),
								// h('a', {
								//     class: 'icon_del',
								//     on: {
								//       click: () => {
								//         this.$Modal.confirm({
								//           title: '提醒',
								//           content: '确定删除吗',
								//           onOk: () => {
								//             this.handleSingleRemove(params.index)
								//           }
								//         })
								//       }
								//     }
								// })
							]);
						} else {
							return h('div', [
								h('a', {
									class: 'icon_eye',
									style: {
										marginRight: 0,
									},
									on: {
										click: () => {
											let id = params.row.id;
											this.$router.push({
												name: 'TrainReportDetail',
												params: { member_id: this.memberId, train_id: id },
											});
										},
									},
								}),
							]);
						}
					},
				},
			],
			trainData: {
				page: 1,
				size: 10,
			},
			trainList: [],
			pageTotal: 0,
			cur_page: 1,
			deleteItem: {
				ids: [],
			},
			power: [],
		};
	},
	computed: {
		memberId() {
			return this.$route.params.member_id;
		},
		userInfo() {
			return this.$store.getters.userInfo;
		},
		monitorId() {
			return this.$route.params.monitorId;
		},
	},
	methods: {
		handleInit() {
			this.trainData.member_id = this.memberId;
			MemberService.trainReportList(this.trainData).then((data) => {
				this.trainList = data.list;
				this.pageTotal = data.row_size;
				this.cur_page = +data.cur_page;
			});
		},
		changePage(page) {
			this.trainData.page = page;
			this.handleInit();
		},
		handleSelect(selection) {
			this.deleteItem.ids = [];
			selection.forEach((item) => {
				this.deleteItem.ids.push(item.id);
			});
		},
	},
	mounted() {
		this.power = this.userInfo.power;
		this.handleInit();
	},
};
</script>
<style lang="css" scoped>
.data-contain .table-box {
  margin: 0;
}
.scheme-contain .data-contain {border: none;}

.card-wrapper {
  overflow: hidden;
  margin-bottom: 20px;
}
</style>
